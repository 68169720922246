import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';
import { PdfDialogComponent } from './pdf-dialog/pdf-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CustomMaxDirective } from '../shared/directives/custom-max-validator.directive';
import { CustomMinDirective } from '../shared/directives/custom-min-validator.directive';
import { ViewImageComponent } from './components/view-image/view-image.component';
import { TableFootComponent } from './components/table-foot/table-foot.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AdminProfileChangeComponent } from './components/admin-profile-change/admin-profile-change.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { UserService } from '../super-admin/user/user.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NewPatComponent } from '../shared/components/new-pat/new-pat.component';
import { ListOfEmployeesComponent } from '../shared/components/list-of-employees/list-of-employees.component';
import { NewEvaluationComponent } from '../shared/components/new-evaluation/new-evaluation.component';
import { EvaluationComponent } from '../shared/components/evaluation/evaluation.component';
import { EmployeeTeamPopUpComponent } from '../shared/components/employee-team-pop-up/employee-team-pop-up.component';
import { ProfileTooltip } from '../employee/wall/profile-tooltip/profile-tooltip.component';
import { GiveTokenComponent } from '../shared/components/give-token/give-token.component';
import { FooterComponent } from '../components/common/footer/footer.component';
//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxSelectModule } from 'ngx-select-ex';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ChoicePopUpComponent } from './components/choice-pop-up/choice-pop-up.component';
import { AskEvaluationComponent } from './components/ask-evaluation/ask-evaluation.component';

import { NativeStorage } from '@ionic-native/native-storage/ngx';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Camera } from '@ionic-native/camera/ngx';

import { AuthService } from '../auth/auth.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AVInterceptor } from './services/avinterceptor';

//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PopoverModule } from 'ngx-bootstrap/popover';
//import { DeviceDetectorModule } from 'ngx-device-detector';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'angular2-moment';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { IosAutocompleteComponent } from './components/ios-autocomplete/ios-autocomplete.component';
import { LocalizedDatePipe } from './pipes/date.pipe';
import { NewLinePipe } from './pipes/newine.pipe';

import { OnlyNumberDirective } from '../shared/directives/only-number.directive';
import { OnlyIntegerDirective } from '../shared/directives/only-integer.directive';
import { MaxNumberDirective } from '../shared/directives/maxNumber.directive';
import { MinNumberDirective } from '../shared/directives/minNumber.directive';
import { TooltipDirective } from '../shared/directives/tooltip.directive';
import { EmployeeDialogComponent } from './employee-dialog/employee-dialog.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ImageSelectorComponent } from '../company-report/objectives-individual/objective/obj-sheet-popup/image-selector/image-selector.component';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { TrimPipe } from './pipes/trim.pipe';
import { DeferDialogComponent } from './defer-dialog/defer-dialog.component';
import { CloneCycleDialogComponent } from './clone-cycle-dialog/clone-cycle-dialog.component';

import { ThemeModule } from 'app/shared/theme/theme.module';
import { LinkyModule } from 'ngx-linky';
import { CheckMinMaxDirective } from './directives/check-min-max.directive';
import { ImportConfigurationComponent } from './import-configuration/import-configuration.component';
import { DebounceClickDirective } from './directives/debounce-click-directive.directive';

import { FeedNewsComponent } from 'app/employee/wall/feed-news/feed-news.component';
import { FeedNewsEventComponent } from 'app/employee/wall/feed-news-event/feed-news-event.component';
import { FeedAskComponent } from 'app/employee/wall/feed-ask/feed-ask.component';
import { FeedFeedbackComponent } from 'app/employee/wall/feed-feedback/feed-feedbak.component';
import { FeedPatComponent } from 'app/employee/wall/feed-pat/feed-pat.component';
import { FeedRepatComponent } from 'app/employee/wall/feed-repat/feed-repat.component';
import { FeedTokenComponent } from 'app/employee/wall/feed-token/feed-token.component';
import { TruncatePipe } from 'app/app.pipe';
import { MenuComponent } from 'app/employee/wall/menu/menu.component';
import { SendMailDialogComponent } from './send-mail-dialog/send-mail-dialog.component';

import { Pp360ComponentsModule } from 'app/pp360-components/pp360-components.module';
import { AvaD3OrgchartComponent } from 'app/components/common/ava-d3-orgchart/ava-d3-orgchart.component';
import { FilterColumnComponent } from 'app/company-admin/performace-reviews/components/filter-column/filter-column.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImageUploadDialogComponent } from './components/image-upload-dialog/image-upload-dialog.component';
import { DragDirective } from './components/image-upload-dialog/dragDrop.directive';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { CustomImageCropperComponent } from './components/custom-image-cropper/custom-image-cropper.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { StickyPopoverDirective } from './directives/sticky-popover.directive';
import { WallFilterDropdownComponent } from 'app/employee/wall/feed/wall-filter-dropdown/wall-filter-dropdown.component';
import { PostCommentComponent } from './components/post-comment/post-comment.component';
import { Okrs } from './components/okrs/okrs.component';
import { PostCommentItemComponent } from './components/post-comment-item/post-comment-item.component';
import { PostCommentItemsComponent } from './components/post-comment-items/post-comment-items.component';
import { AskFilterDropdownComponent } from './components/ask-filter-dropdown/ask-filter-dropdown.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from './components/camera/camera.component';
import { EngagementComponent } from './components/engagement/engagement.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ManagerOkrPopupComponent } from './components/manager-okr-popup/manager-okr-popup.component';
import { EngagementPopupComponent } from './components/engagement-popup/engagement-popup.component';
import { EngagementPopupPlusComponent } from './components/engagement-popup-plus/engagement-popup-plus.component';
import { ViewFeedPopupComponent } from './components/view-feed-popup/view-feed-popup.component';
import { WallService } from 'app/employee/wall/wall.service';
import { CustomOkrDropdownComponent } from './components/custom-okr-dropdown/custom-okr-dropdown.component';
import { NewsDialogComponent } from 'app/employee/wall/news-dialog/news-dialog.component';
import { NewReceivedCfComponent } from './components/new-received-cf/new-received-cf.component';
import { OkrFollowerPopupComponent } from './components/okr-follower-popup/okr-follower-popup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ViewOkrsCycleComponent } from './components/view-okrs-cycle/view-okrs-cycle.component';
import { TableSkillsBySkillComponent } from 'app/personal-growth/praise/praise-desktop/table-skills-by-skill/table-skills-by-skill.component';
import { TableSkillsByPersonComponent } from 'app/personal-growth/praise/praise-desktop/table-skills-by-person/table-skills-by-person.component';
import { TableObjectivesByPersonComponent } from 'app/personal-growth/praise/praise-desktop/table-objectives-by-person/table-objectives-by-person.component';
import { TableObjectivesByCategoryComponent } from 'app/personal-growth/praise/praise-desktop/table-objectives-by-category/table-objectives-by-category.component';
import { PraiseService } from 'app/personal-growth/praise/praise.service';
import { AvaCytoscapeComponent } from 'app/components/common/ava-cytoscape/ava-cytoscape.component';
import { ThemeChangeComponent } from 'app/employee/wall/theme-change/theme-change.component';
import { CustomRadioDropdownComponent } from './components/custom-radio-dropdown/custom-radio-dropdown.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { BottomSheetOverviewComponent } from './components/bottom-sheet-overview/bottom-sheet-overview.component';
import { BottomSheetMobileDropdownComponent } from './components/bottom-sheet-mobile-dropdown/bottom-sheet-mobile-dropdown.component';
import { CustomMobileRadioDropdownComponent } from './components/custom-mobile-radio-dropdown/custom-mobile-radio-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ButtonsModule.forRoot(),
    AngularMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    //NgMultiSelectDropDownModule,
    NgxSelectModule,
    //BrowserAnimationsModule,
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    PaginationModule.forRoot(),
    // DeviceDetectorModule.forRoot()
    TranslateModule.forChild(),
    MomentModule,
    PdfViewerModule,
    InfiniteScrollModule,
    ThemeModule,
    LinkyModule,
    Pp360ComponentsModule,
    MatAutocompleteModule,
    ScrollingModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTooltipModule,
    ImageCropperModule,
    MatRadioModule,
    NgSelectModule,
    PickerModule,
    DragDropModule,
    WebcamModule,
    MatBottomSheetModule,
    MatListModule,
  ],
  declarations: [
    SimpleDialogComponent,
    DeferDialogComponent,
    CloneCycleDialogComponent,
    PdfDialogComponent,
    ConfirmDialogComponent,
    GiveTokenComponent,
    ListOfEmployeesComponent,
    EmployeeTeamPopUpComponent,
    ProfileTooltip,
    NewPatComponent,
    IosAutocompleteComponent,
    NewEvaluationComponent,
    EvaluationComponent,
    CustomMinDirective,
    CustomMaxDirective,
    ClickOutsideDirective,
    AskEvaluationComponent,
    ViewImageComponent,
    TableFootComponent,
    FooterComponent,
    AdminProfileChangeComponent,
    PasswordChangeComponent,
    ChoicePopUpComponent,
    IosAutocompleteComponent,
    ImportConfigurationComponent,
    LocalizedDatePipe,
    NewLinePipe,
    OnlyNumberDirective,
    OnlyIntegerDirective,
    MaxNumberDirective,
    MinNumberDirective,
    TooltipDirective,
    EmployeeDialogComponent,
    ImageSelectorComponent,
    TrimPipe,
    DeferDialogComponent,
    CloneCycleDialogComponent,
    CheckMinMaxDirective,
    DebounceClickDirective,
    FeedNewsComponent,
    FeedNewsEventComponent,
    FeedAskComponent,
    FeedFeedbackComponent,
    FeedPatComponent,
    FeedRepatComponent,
    FeedTokenComponent,
    TruncatePipe,
    MenuComponent,
    SendMailDialogComponent,
    AvaD3OrgchartComponent,
    FilterColumnComponent,
    ImageUploadDialogComponent,
    DragDirective,
    RadioButtonComponent,
    CustomDropdownComponent,
    CustomImageCropperComponent,
    CustomSelectComponent,
    WallFilterDropdownComponent,
    AskFilterDropdownComponent,
    StickyPopoverDirective,
    PostCommentComponent,
    Okrs,
    PostCommentItemComponent,
    PostCommentItemsComponent,
    CameraComponent,
    EngagementComponent,
    ManagerOkrPopupComponent,
    EngagementPopupComponent,
    EngagementPopupPlusComponent,
    ViewFeedPopupComponent,
    CustomOkrDropdownComponent,
    NewsDialogComponent,
    NewReceivedCfComponent,
    OkrFollowerPopupComponent,
    ViewOkrsCycleComponent,
    TableSkillsBySkillComponent,
    TableSkillsByPersonComponent,
    TableObjectivesByPersonComponent,
    TableObjectivesByCategoryComponent,
    AvaCytoscapeComponent,
    ThemeChangeComponent,
    CustomRadioDropdownComponent,
    BottomSheetOverviewComponent,
    CustomMobileRadioDropdownComponent,
    BottomSheetMobileDropdownComponent,
  ],
  exports: [
    FormsModule,
    PickerModule,
    ReactiveFormsModule,
    CommonModule,
    PaginationModule,
    TooltipModule,
    CustomMinDirective,
    CustomMaxDirective,
    ClickOutsideDirective,
    ModalModule,
    TypeaheadModule,
    TabsModule,
    BsDatepickerModule,
    TableFootComponent,
    FooterComponent,
    ImportConfigurationComponent,
    BsDropdownModule,
    CollapseModule,
    TranslateModule,
    MatTooltipModule,
    LocalizedDatePipe,
    NewLinePipe,
    TrimPipe,
    MomentModule,
    OnlyNumberDirective,
    OnlyIntegerDirective,
    MaxNumberDirective,
    MinNumberDirective,
    EmployeeDialogComponent,
    TooltipDirective,
    ThemeModule,
    WebcamModule,
    LinkyModule,
    CheckMinMaxDirective,
    DebounceClickDirective,
    FeedNewsComponent,
    FeedNewsEventComponent,
    FeedAskComponent,
    FeedFeedbackComponent,
    FeedPatComponent,
    FeedRepatComponent,
    FeedTokenComponent,
    TruncatePipe,
    MenuComponent,
    AvaD3OrgchartComponent,
    FilterColumnComponent,
    MatSelectModule,
    MatFormFieldModule,
    ImageUploadDialogComponent,
    MatRadioModule,
    RadioButtonComponent,
    PopoverModule,
    CustomDropdownComponent,
    CustomSelectComponent,
    WallFilterDropdownComponent,
    AskFilterDropdownComponent,
    StickyPopoverDirective,
    PostCommentComponent,
    Okrs,
    PostCommentItemComponent,
    PostCommentItemsComponent,
    CameraComponent,
    EngagementComponent,
    PasswordChangeComponent,
    CameraComponent,
    CustomOkrDropdownComponent,
    NewsDialogComponent,
    TableSkillsBySkillComponent,
    TableSkillsByPersonComponent,
    TableObjectivesByPersonComponent,
    TableObjectivesByCategoryComponent,
    AvaCytoscapeComponent,
    ThemeChangeComponent,
    CustomRadioDropdownComponent,
    CustomMobileRadioDropdownComponent,
    BottomSheetMobileDropdownComponent,
  ],
  providers: [
    NativeStorage,
    Camera,
    BsModalService,
    BsModalRef,
    UserService,
    AuthService,
    IosAutocompleteComponent,
    WallService,
    PraiseService,
    { provide: HTTP_INTERCEPTORS, useClass: AVInterceptor, multi: true },
  ],
  entryComponents: [
    SimpleDialogComponent,
    DeferDialogComponent,
    CloneCycleDialogComponent,
    PdfDialogComponent,
    ConfirmDialogComponent,
    GiveTokenComponent,
    ViewImageComponent,
    AdminProfileChangeComponent,
    PasswordChangeComponent,
    NewPatComponent,
    IosAutocompleteComponent,
    ChoicePopUpComponent,
    AskEvaluationComponent,
    ListOfEmployeesComponent,
    NewEvaluationComponent,
    EvaluationComponent,
    EmployeeTeamPopUpComponent,
    ProfileTooltip,
    EmployeeDialogComponent,
    ImageSelectorComponent,
    ImportConfigurationComponent,
    SendMailDialogComponent,
  ],
})
export class SharedModule {}
