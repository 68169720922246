import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { CoreService } from 'app/shared/services/core.service';
@Component({
  selector: 'bottom-sheet-overview',
  templateUrl: './bottom-sheet-overview.component.html',
  styleUrls: ['./bottom-sheet-overview.component.scss'],
})
export class BottomSheetOverviewComponent implements OnInit {
  selected: any;
  constructor(
    public coreService: CoreService,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.selected = this.data[0].selectedItem;
  }

  onChange(item) {
    if (item?.Id) {
      setTimeout(() => {
        this.data[0].selectedItem = item;
        this.coreService.setRadioSelect(
          this.data[0].selectedItem ? this.data[0].selectedItem : []
        );
        this.data[0].selectedItem = null;
      }, 10);
    } else if (item?.value) {
      setTimeout(() => {
        this.data[0].selectedItem = item;
        this.coreService.setRadioSelect(
          this.data[0].selectedItem ? this.data[0].selectedItem : []
        );
        this.data[0].selectedItem = null;
      }, 10);
    } else if (item?.Value) {
      setTimeout(() => {
        this.data[0].selectedItem = item;
        this.coreService.setRadioSelect(
          this.data[0].selectedItem ? this.data[0].selectedItem : []
        );
        this.data[0].selectedItem = null;
      }, 10);
    }
  }

  onChangeCategory(item) {
    if (item?.Id) {
      setTimeout(() => {
        this.data[0].selectedItem = item;
        this.coreService.setRadioSelect(
          this.data[0].selectedItem ? this.data[0].selectedItem : []
        );
        this.data[0].selectedItem = null;
      }, 10);
    }
  }

  onChangeVisibility(item) {
    if (item?.Value) {
      setTimeout(() => {
        this.data[0].selectedItem = item;
        this.coreService.setRadioSelect(
          this.data[0].selectedItem ? this.data[0].selectedItem : []
        );
        this.data[0].selectedItem = null;
      }, 10);
    }
  }

  closeBottomSheet() {
    this._bottomSheetRef.dismiss();
    this.coreService.setRadioSelect(this.data[0].selectedItem);
  }
}
