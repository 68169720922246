import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { CoreService } from 'app/shared/services/core.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BottomSheetMobileDropdownComponent } from '../bottom-sheet-mobile-dropdown/bottom-sheet-mobile-dropdown.component';

@Component({
  selector: 'custom-mobile-radio-dropdown',
  templateUrl: './custom-mobile-radio-dropdown.component.html',
  styleUrls: ['./custom-mobile-radio-dropdown.component.scss'],
})
export class CustomMobileRadioDropdownComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('searchInput') ngSelect: NgSelectComponent;
  @Output() itemSelected = new EventEmitter();
  @Input() options: any[] = [];
  @Input() multiple = false;
  @Input() searchable = true;
  @Input() clearable = true;
  @Input() disabled = false;
  @Input() isRequired = false;
  @Input() defaultValue;
  @Input() bindLabel = 'Name';
  @Input() optionLabel = 'Name';
  @Input() showArrow = false;
  @Input() type: string;
  @Input() id: string;
  @Input() isOpen = false;
  @Input() titleName: string;
  @Input() isBackDrop = false;
  isCalander: boolean = false;
  selectedItem;
  showMandatory: boolean = false;
  subscription: Subscription;

  constructor(
    public coreService: CoreService,
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    if (this.defaultValue) {
      this.selectedItem = this.defaultValue[0]
        ? this.defaultValue[0]
        : this.defaultValue;
    }

    if (this.isOpen) {
      this.openBottomSheet();
    }

    this.subscription = this.coreService.getRadioSelect().subscribe((item) => {
      if (item && item.id === this.id) {
        this.selectedItem = item.item;
        this.selectionChange(item.item);
      }
    });
    this.isBackDrop = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isOpen) {
      this.openBottomSheet();
    }
    if (changes && changes.options) {
      if (
        changes.options.currentValue &&
        !changes.options.currentValue.length
      ) {
        this.selectedItem = null;
      }
    }
  }

  selectionChange(e) {
    this.itemSelected.emit(e);
  }

  openBottomSheet(): void {
    const data = {
      options: this.options,
      title: this.titleName,
      selectedItem: this.selectedItem,
      id: this.id,
    };

    this._bottomSheet.open(BottomSheetMobileDropdownComponent, { data });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
